import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AccountActivationAcceptComponent } from './account-activation/account-activation-accept/account-activation-accept.component';
import { AccountActivationDeclineComponent } from './account-activation/account-activation-decline/account-activation-decline.component';
import { AuthComponent } from './auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { ExpiredLinkModalComponent } from './forgot-password/expired-link-modal/expired-link-modal.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { PrivacyAndTermsComponent } from './privacy-and-terms/privacy-and-terms.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { SharedModule } from '../shared/shared.module';

// Angular Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule} from '@angular/material/dialog';
import { SsoComponent } from './sso/sso.component';

@NgModule({
    imports: [
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        AuthRoutingModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        AuthComponent
    ],
    declarations: [
        AccountActivationAcceptComponent,
        AccountActivationDeclineComponent,
        AuthComponent,
        ExpiredLinkModalComponent,
        ForgotPasswordComponent,
        LoginComponent,
        PrivacyAndTermsComponent,
        ResetPasswordComponent,
        SsoComponent
    ],
    providers: [],
    bootstrap: [AuthComponent]
})
export class AuthModule { }
