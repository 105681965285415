/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import momentTimezone from 'moment-timezone';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HpHcpUserSession } from './hp-hcp-user-session.service';
import { HTTP_UNAUTHORIZED } from '../constants/http-response-codes';
import { Router } from '@angular/router';
import { AppConstants } from '../../config/constants';

const SURGEON_REFERENCE_BY_ID_PATH = '/api/v1/staff/surgeon/-';

/*
 * @author      @version    @date           @description
 * HSenevir     01          Mar 10, 2023    AFLL-15210 vras case report uploads and enabled multipart file uploads
 * JVeerasa     02          Nov 17, 2023    AFLL-20214 | VDP | Docspera Non EHR | Use velys patient id for intake form API call
 * PGnana       03          Dec 06, 2023    AFLL-19353 Verification 8.0.0 | VDP UK and US | Portal | Attempting to login simultaneously using same user with different browser or different machine it is displaying “15 minute in activity screen” for first user
 * RPenimos     04          Dec 13, 2023    AFLL-20253 VPP Portal | Update VRAS Case Report Surgeon tab UI
 * RPenimos     05          Jan 04, 2024    AFLL-20289 VPP/VI Portal | Generate surgeon's report PDF in VPP and VI Portal 
*/
@Injectable()
export class HpHcpHttp {

    public requestInProgress = false;

    public urls = {
        actionList: {
            actionListData: '/api/v1/user/-/actionList',
            changeActionItemStatus: '/api/v1/updateActionItem'
        },
        appointment: {
            createAppointment: '/api/v1/staff/user/-/createAppointment',
            updateAppointment: '/api/v1/staff/user/-/updateAppointment',
            telehealthInvite: '/api/v1/user/docspera/-/appointment/',
            getAppointments: '/api/v1/user/-/appointments'
        },
        bctTracker: {
            save: '/api/v1/bctEvent'
        },
        chart: {
            stepData: '/api/v1/user/-/healthybehaviors/steps/chart_data',
            vasPainData: '/api/v1/user/-/healthybehaviors/pain/chart_data',
            romData: 'api/v1/user/-/healthybehaviors/rom/chart_data',
            koosData: 'api/v1/user/-/healthybehaviors/koosjr/chart_data',
            hoosData: 'api/v1/user/-/healthybehaviors/hoosjr/chart_data',
            oxfordKneeData: 'api/v1/user/-/healthybehaviors/oxford_knee/chart_data',
            asesData: 'api/v1/user/-/healthybehaviors/ases/chart_data',
            preOpBaselineData: 'api/v1/user/-/cta/preopBaseline',
            oxfordShoulderData: 'api/v1/user/-/healthybehaviors/oxford_shoulder/chart_data',
            oxfordHipData: 'api/v1/user/-/healthybehaviors/oxford_hip/chart_data'
        },
        cohort: {
            cohortData: '/api/v1/cohortDataByProviderId'
        },
        config: {
            carePlans: '/api/v1/config/care-plans',
            procedure: 'api/v1/staff/nonEmr/carePlan',
            siteLevelCarePlans: '/api/v1/site'
        },
        doctor: {
            list: '/api/v1/staff/site/-/staff/hcp'
        },
        document: {
            privacyPolicy: '/docs/privacy',
            termsOfUse: '/docs/terms-of-use'
        },
        emr: {
            fetchPatient: '/api/v1/staff/ehr/-/profile'
        },
        externalSso: {
            signedUrl: '/api/v2/auth/signed-url'
        },
        genericMedia: {
            media: '/api/v2/user/-/mediaFiles',
            mediaContent: '/api/v2/user/mediaFiles',
            setHcpResponse: 'api/v2/user/-/mediaFile'
        },
        hcpCheckList: 'api/v1/user/docspera/-/hcpChecklist',
        intakeForm: {
            sendIntakeForm: 'api/v1/user/-/intakeForm',
            intakeFormStatus: 'api/v1/user/docspera/-/intakeFormStatus'
        },
        notification: {
            actions: '/api/v1/notification/actions',
            disconnect: '/api/v1/user/-/disconnect-notification',
            update: '/api/v1/user/-/notification'
        },
        organization: {
            mutiOrgSelection: '/api/v1/staff/organization'
        },
        patient: {
            actionItem: '/api/v1/user/-/actionItem',
            actionItems: '/api/v1/staff/carePlan/-/actionItems',
            actionList: '/api/v1/user/-/actionList',
            caloriesChartAllData: '/api/v1/user/-/healthybehaviors/calories/chart_data',
            cancelSurgery: '/api/v1/user/-/cancelSurgery',
            careModule: '/api/v1/organization/-/care-plan',
            chartMilestones: '/api/v1/user/-/milestones',
            edit: '/api/v1/staff/user/-',
            archive: 'api/v1/user/archive',
            recover: 'api/v1/user/recover',
            emrProfile: '/api/v1/user/site/-/emr/-/profile',
            mrnProfile: '/api/v1/user/mrn/profile',
            emrSave: '/api/v1/staff/emr/retrieve',
            essentialEducation: '/api/v1/user/-/essentialEducation',
            filtersSaved: '/api/v1/staff/patient-filter',
            filtersSorts: '/api/v1/staff/site/-/users/filters-sorts',
            historyLog: '/api/v1/user/-/eventsLog',
            intraOpNotes: 'api/v1/user/-/intraOpNotes',
            list: '/api/v1/staff/site/-/users',
            mail: '/api/v1/staff/user/-/sendConnectionKey',
            sms: '/api/v1/staff/user/-/sendSms',
            nauseaChartAllData: '/api/v1/user/-/healthybehaviors/nausea/chart_data',
            notifications: '/api/v1/user/-/notifications',
            painChartAllData: '/api/v1/user/-/healthybehaviors/pain/chart_data',
            phoneType: '/api/v1/phoneType',
            preApprovalActionItems: '/api/v1/staff/carePlan/-/actionItems/preApproval',
            preApprovalInsurers: '/api/v1/staff/insurers',
            profile: '/api/v1/user/-/profile',
            proteinChartAllData: '/api/v1/user/-/healthybehaviors/protein_intake/chart_data',
            refreshKey: '/api/v1/staff/user/-/connect',
            register: '/api/v1/staff/user',
            riskScore: '/api/v1/cohortAndPatientDataByPatientId',
            sites: '/api/v1/staff/-/sites',
            stepsChartAllData: '/api/v1/user/-/healthybehaviors/steps/chart_data',
            trackerStatus: '/api/v1/user/-/trackerStatus',
            waterChartAllData: '/api/v1/user/-/healthybehaviors/water_intake/chart_data',
            validationNoEMR: 'api/v1/staff/nonEmr',
            weightChartAllData: '/api/v1/user/-/healthybehaviors/weight/chart_data'
        },
        user: {
            acceptLegal: '/api/v1/staff/login/accept',
            activateStaffAccount: '/api/v1/staff/activateAccount',
            declineAccountActivation: '/api/v1/staff/declineActivation',
            emailPreferences: 'api/v1/staff/emailPreferences',
            forgotPassword: 'api/v1/staff/forgotPassword',
            forgotPasswordReset: 'api/v1/-/forgotPasswordReset',
            login: '/api/v1/staff/login',
            sso: '/api/v2/sso',
            logout: '/api/v1/staff/logout',
            resetPassword: 'api/v1/staff/resetPassword',
            verifyPasswordResetKey: 'api/v1/-/verifyPasswordResetKey',
            verifyStaffInvitationCode: 'api/v1/staff/verify'
        },
        users: {
            edit: '/api/v1/staff/staff/-',
            list: '/api/v1/staff/organization/staff',
            reinvite: '/api/v1/staff/reinviteStaff/-',
            resendPassword: '/api/v1/staff/-/resendPassword',
            roles: '/api/v1/staff/organization/roles',
            save: '/api/v1/staff/staff',
            setActivation: '/api/v1/staff/setActivation/-',
            sites: '/api/v1/staff/organization/sites',
            user: '/api/v1/staff/staff/-'
        },
        site: {
            location: '/api/v1/site/-/locations'
        },
        surgeon: {
            delete: SURGEON_REFERENCE_BY_ID_PATH,
            edit: SURGEON_REFERENCE_BY_ID_PATH,
            view: SURGEON_REFERENCE_BY_ID_PATH,
            viewAll: '/api/v1/staff/organization/surgeons',
            save: '/api/v1/staff/surgeon'
        },
        report: {
            createReportQueue: '/api/v1/reports',
            listReports: '/api/v1/reports',
            downloadReport: '/api/v1/reports/-/download'
        },
        vras: {
            verifyCaseFile: '/api/v1/vras/caseReport',
            caseReport: '/api/v1/vras/users/-/caseReport',
            downloadCaseReport: '/api/v1/vras/users/-/caseReport/download',
            alignmentImages: '/api/v1/vras/patients/-/alignment',
            downloadPDF: '/api/v1/vras/patients/-/generate-pdf'
        }
    };

    constructor(private _http: HttpClient,
        private _userSessionService: HpHcpUserSession, private router: Router) { }

    public get<T>(resourceUrl: string, optionalParams: any = {}, asHtml = false): Observable<T> {
        this.requestInProgress = true;
        this._userSessionService.updateSessionExpirationActivity();

        const { queryParams, urlMultiParams, urlParam } = optionalParams;

        const query = this._getQueryString(queryParams);

        if (urlMultiParams) {
            resourceUrl = this._setUrlMultiParams(resourceUrl, urlMultiParams);
        } else {
            resourceUrl = this._setUrlParam(resourceUrl, urlParam);
        }

        let headers = new HttpHeaders({ 'timezone': momentTimezone.tz.guess(true) });
        let options = { headers };
        return this._http.get(`${resourceUrl}${query}`, options)
            .pipe(
                map(response => {
                    this.requestInProgress = false;
                    if (asHtml) {
                        return response['_body'];
                    }
                    return response;
                }),
                catchError(this._handleError.bind(this))
            );
    }

    public post(resourceUrl: string, optionalParams: any = {}): Observable<Object> {
        this.requestInProgress = true;
        this._userSessionService.updateSessionExpirationActivity();

        const { queryParams, requestBody, urlParam, isFormData } = optionalParams;

        const query = this._getQueryString(queryParams);
        resourceUrl = this._setUrlParam(resourceUrl, urlParam);

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'timezone': momentTimezone.tz.guess(true)
        });
        if (isFormData) {
            headers = new HttpHeaders({
                'timezone': momentTimezone.tz.guess(true)
            });
        }
        let options = { headers };

        return this._http.post(`${resourceUrl}${query}`, isFormData ? requestBody : JSON.stringify(requestBody), options)
            .pipe(
                map(response => {
                    this.requestInProgress = false;
                    return response;
                }),
                catchError(this._handleError.bind(this))
            );
    }

    public put(resourceUrl: string, optionalParams: any = {}): Observable<Object> {
        this.requestInProgress = true;
        this._userSessionService.updateSessionExpirationActivity();

        const { queryParams, requestBody, urlParam } = optionalParams;

        const query = this._getQueryString(queryParams);
        resourceUrl = this._setUrlParam(resourceUrl, urlParam);

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'timezone': momentTimezone.tz.guess(true)
        });
        let options = { headers };
        return this._http.put(`${resourceUrl}${query}`, JSON.stringify(requestBody), options)
            .pipe(
                map(response => {
                    this.requestInProgress = false;
                    return response;
                }),
                catchError(this._handleError.bind(this))
            );
    }

    public delete(resourceUrl: string, optionalParams: any = {}): Observable<Object> {
        this.requestInProgress = true;
        this._userSessionService.updateSessionExpirationActivity();

        const { queryParams, urlParam } = optionalParams;

        const query = this._getQueryString(queryParams);

        resourceUrl = this._setUrlParam(resourceUrl, urlParam);

        let headers = new HttpHeaders({ 'timezone': momentTimezone.tz.guess(true) });
        let options = { headers };

        return this._http.delete(`${resourceUrl}${query}`, options)
            .pipe(
                map(response => {
                    this.requestInProgress = false;
                    return response;
                }),
                catchError(this._handleError.bind(this))
            );
    }

    public isRequestInProgress() {
        return this.requestInProgress;
    }

    private _getQueryString(queryParamsList: Array<any>): string {
        if (!queryParamsList) {
            return '';
        }

        const queryString = queryParamsList
            .map(({ name, value }) => `${encodeURIComponent(name)}=${encodeURIComponent(value)}`)
            .join('&');
        return `?${queryString}`;
    }

    private _handleError(error: HttpErrorResponse) {
        this.requestInProgress = false;
        let errorMessage = error.error || 'Server error';

        let logoutPrams = '';
        if (window.name) {
            logoutPrams = '&cl=false';
        }

        if (!this.router.url.startsWith('/sso') && error.status === HTTP_UNAUTHORIZED) {
            this._userSessionService.clearSession();
            if (error.error || error.error.message || error.error.message === 'sessionInterrupted') {
                window.location.href = `${AppConstants.vdsUrl}/#/login?sessionInterrupted=true${logoutPrams}`;
            } else {
                window.location.href = `${AppConstants.vdsUrl}/#/login?inactivity=true${logoutPrams}`;
            }
            errorMessage = 'Session expired';
        }
        return throwError(errorMessage);
    }

    private _setUrlParam(resourceUrl: string, parameter: number | string) {
        if (!parameter) {
            return resourceUrl;
        }

        return resourceUrl.replace(/\/-(?=\/)?/, `/${parameter}`);
    }

    private _setUrlMultiParams(resourceUrl, parameters: string[]) {
        if (!parameters) {
            return resourceUrl;
        }
        return resourceUrl.replace(/\/-(?=\/)?/g, () => `/${parameters.shift()}`);
    }

}
